import {useEffect} from 'react';

/* TODO: Using root.style.setProperty to override pulse variables inline on the <html> element because CSP blocks dynamically added <style> tags.
    Modifying the Content Security Policy (CSP) to allow inline styles ('unsafe-inline') is insecure and not advisable.
    Therefore, setting inline styles on <html> is the practical solution to dynamically update branding colors within CSP restrictions. */
export default function useUpdateThemeColors(branding, isBrandingLoaded) {
    const root = document.documentElement;
    const mode = root.getAttribute('data-mode');

    useEffect(() => {
        if (isBrandingLoaded && branding?.colors && branding?.colors[mode]) {
            const colorMapping = {
                'color-brand-primary1': 'primary_1',
                'color-brand-primary2': 'primary_2',
                'color-brand-primary3': 'primary_3',
                'color-brand-secondary1': 'secondary_1',
                'color-brand-secondary2': 'secondary_2',
                'color-brand-secondary3': 'secondary_3',
            };

            if (!mode || !branding.colors[mode]) {
                return;
            }

            Object.entries(colorMapping).forEach(([cssVar, brandColorKey]) => {
                const cssVarName = `--${cssVar}`;
                const cssValue = branding.colors[mode][brandColorKey];

                if (cssValue) {
                    root.style.setProperty(cssVarName, cssValue);
                }
            });
        }
    }, [branding, isBrandingLoaded, root, mode]);
}
