// List of Paths: https://github.com/Teladoc/web-sdk-legacy/blob/main/packages/websdk/src/resource/paths.js
import {paths} from '@td/websdk';
import {lazy} from 'react';
import reduce from 'lodash/reduce';
import IconHelpDefault from '@teladoc/pulse/icons/help-default.svg';
import IconLockDefault from '@teladoc/pulse/icons/lock-default.svg';
import IconProfileDefault from '@teladoc/pulse/icons/profile-default.svg';
import IconReminderDefault from '@teladoc/pulse/icons/reminder-default.svg';
import IconMedicineDefault from '@teladoc/pulse/icons/medicine-default.svg';
import IconGroupDefault from '@teladoc/pulse/icons/group-default.svg';
import IconAttachmentDefault from '@teladoc/pulse/icons/attachment-default.svg';
import IconHealthInfoDefault from '@teladoc/pulse/icons/health-info-default.svg';
import IconPrimaryCareDefault from '@teladoc/pulse/icons/primary-care-default.svg';
import IconMedicalInfoDefault from '@teladoc/pulse/icons/medical-info-default.svg';
import IconEmergencyContactDefault from '@teladoc/pulse/icons/emergency-contact-default.svg';
import IconFamilyDefault from '@teladoc/pulse/icons/family-default.svg';
import IconCreditCardDefault from '@teladoc/pulse/icons/credit-card-default.svg';
import IconChecklistDefault from '@teladoc/pulse/icons/checklist-default.svg';
import IconHomeDefault from '@teladoc/pulse/icons/home-default.svg';
import IconChatDefault from '@teladoc/pulse/icons/chat-default.svg';
import IconSignOutDefault from '@teladoc/pulse/icons/sign-out-default.svg';
import IconProgramsDefault from '@teladoc/pulse/icons/programs-default.svg';
import IconDocumentDefault from '@teladoc/pulse/icons/document-default.svg';
import IconCalendarDefault from '@teladoc/pulse/icons/calendar-default.svg';
import IconGroupActive from '@teladoc/pulse/icons/group-active.svg';
import IconHeartPulseDefault from '@teladoc/pulse/icons/heart-pulse-default.svg';
import IconLogsDefault from '@teladoc/pulse/icons/logs-default.svg';
import IconDeviceDefault from '@teladoc/pulse/icons/device-default.svg';
import IconSuppliesDefault from '@teladoc/pulse/icons/supplies-default.svg';
import IconProgramsActive from '@teladoc/pulse/icons/programs-active.svg';
import IconHomeActive from '@teladoc/pulse/icons/home-active.svg';
import IconHealthInfoActive from '@teladoc/pulse/icons/health-info-active.svg';
import IconChatActive from '@teladoc/pulse/icons/chat-active.svg';
import IconProfileActive from '@teladoc/pulse/icons/profile-active.svg';
import {programRoutes as CCMRoutes} from '@teladoc/fe-ccm/ui/router/routes';
import programs from '@teladoc/fe-ccm/ui/programs';
import UserUtils from '@teladoc/fe-ccm/ui/user/user-utils';
import {NAV_MENU, INBOX_SERVICE_TYPES, EVENT_PROPS} from '@/config';
import IconBehavioralHealthColor from '@/icons/behavioral-health-color.svg';
import IconHeartPulseColor from '@/icons/heart-pulse-color.svg';
import Telemed from '@/message-center/telemed/Telemed';
import MentalHealth from '@/message-center/mental-health/MentalHealth';
import ConditionManagement from '@/message-center/condition-management/ConditionManagement';
import IconMenuPartner from '@/icons/menu-icon-partner.svg';

const accountPrefix = '/account';
const healthInfoPrefix = '/health-info';
const messagesPrefix = '/messages';
const telemedPrefix = '';

const {
    bloodSugar,
    deviceFlex,
    bloodPressure,
    heartFailure,
    weight,
    advancedWeight,
    comprehensiveWeight,
    prediabetes,
    advancedPrediabetes,
    comprehensivePrediabetes,
    chronicKidneyDisease,
} = programs;

export const CONDITION_MANAGEMENT_PREFIX = '/condition-management';
export const MENTAL_HEALTH_PREFIX = '/mental-health';
export const YOUR_CASES_PREFIX = '/your-cases';
export const NOT_FOUND = '/not-found';

export const MESSAGE_CENTER_PATH = '/message-center';
export const INBOX_ROUTES = {
    [INBOX_SERVICE_TYPES.CareTeam]: `${MESSAGE_CENTER_PATH}/telemed`,
    [INBOX_SERVICE_TYPES.ChronicCare]: `${MESSAGE_CENTER_PATH}/chronic_care`,
    [INBOX_SERVICE_TYPES.MentalHealth]: `${MESSAGE_CENTER_PATH}/mental-health`,
};

const transformToOneAppCCMRoute = routes =>
    routes.map(route => {
        const {routes: innerRoutes, tabbedRoutes} = route;

        if (innerRoutes) {
            transformToOneAppCCMRoute(innerRoutes);
        }

        if (tabbedRoutes) {
            transformToOneAppCCMRoute(tabbedRoutes);
        }

        route.path = `/condition-management${route.path}`;

        return route;
    });

// CCM subroutes does not include behavioural health
export const CCMSubRoute = transformToOneAppCCMRoute(CCMRoutes).filter(
    route => route.id !== 'behavioralHealth'
);

export const secondaryCCMNavRoutes = [
    {
        id: 'healthProfile',
        path: `${CONDITION_MANAGEMENT_PREFIX}/condition-profile`,
        programSlugs: [],
        navIcon: IconLogsDefault,
        mixpanelEvent: 'profile.health.info.viewed',
        isRelative: true,
        component: lazy(() =>
            /*  */
            import(
                '@teladoc/fe-ccm/ui/profile-preferences/health-profile/HealthProfile'
            )
        ),
    },
    {
        id: 'availablePrograms',
        path: `${CONDITION_MANAGEMENT_PREFIX}/programs`,
        mixpanelEvent: 'settings.programs.cta.clicked',
        navIcon: IconProgramsDefault,
        programSlugs: [],
        isRelative: true,
        component: lazy(() => import('@teladoc/fe-ccm/ui/programs/Programs')),
    },
];

export const otherCCMRoutes = [
    {
        id: 'activateDevices',
        path: `${CONDITION_MANAGEMENT_PREFIX}/activate-devices`,
        isRelative: true,
        component: lazy(() =>
            import(
                '@teladoc/fe-ccm/ui/launchpad/activate-devices/ActivateDevices'
            )
        ),
    },
    {
        id: 'goalDownloadApp',
        path: `${CONDITION_MANAGEMENT_PREFIX}/goal-setting`,
        isRelative: true,
        component: lazy(() =>
            import(
                '@teladoc/fe-ccm/ui/dashboard/home/goal-setting/GoalDownloadApp'
            )
        ),
    },
];

// These routes appear on Program menu in Header
export const programRoutes = [
    {
        id: 'conditionManagement',
        exact: true,
        path: `${CONDITION_MANAGEMENT_PREFIX}/home`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Condition Management',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.CCM,
            level: 2,
            button: EVENT_PROPS.ConditionManagement,
        },
        apptentiveEvent: true,
        icon: IconHeartPulseColor,
        navIcon: IconHeartPulseDefault,
        isHorizontal: true,
        isRelative: true,
        showDecorativeBackground: false,
        activePrograms: true,
        component: lazy(() => import('@teladoc/fe-ccm/ui/dashboard/Dashboard')),
        routes: {...CCMSubRoute, ...secondaryCCMNavRoutes},
    },
    {
        id: 'mentalHealth',
        path: `${MENTAL_HEALTH_PREFIX}/interstitial`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Mental Health',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.MentalHealth,
            level: 2,
            button: EVENT_PROPS.MentalHealth,
        },
        apptentiveEvent: true,
        programSlugs: [],
        isRelative: true,
        icon: IconBehavioralHealthColor,
        isHorizontal: false,
        websdkPath: paths.MYSTRENGTH_INTERSTITIAL_PAGE,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'partnerPrograms',
        path: `/vendor_management/partner_programs`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Partner Programs',
        },
        apptentiveEvent: true,
        isRelative: true,
        icon: IconMenuPartner,
        isHorizontal: false,
        websdkPath: paths.PARTNER_PROGRAMS,
        component: lazy(() => import('@/app/Websdk')),
    },
];

// CCM sidebar route to solve the problem of having two routes with the same id
export const sidebarNavRoutes = [
    {
        id: 'conditionManagementSidebar',
        exact: true,
        path: `${CONDITION_MANAGEMENT_PREFIX}/home`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Condition Management',
        },
        icon: IconHeartPulseColor,
        navIcon: IconHeartPulseDefault,
        isHorizontal: true,
        isRelative: true,
        showDecorativeBackground: false,
        activePrograms: true,
        component: lazy(() => import('@teladoc/fe-ccm/ui/dashboard/Dashboard')),
        routes: {...CCMSubRoute, ...secondaryCCMNavRoutes},
    },
];

// TODO: Get params for some websdk paths
//  All telemed routes that do not appear on Header Menu
export const telemedRoutes = [
    {
        id: 'covid19',
        path: `${telemedPrefix}/covid_19`,
        isRelative: true,
        mixpanelEvent: 'settings.covid19.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.COVID_ASSESSMENT,
    },
    {
        id: 'clinicalAssessment',
        path: `${telemedPrefix}/screens/clinical_assessment`,
        isRelative: true,
        mixpanelEvent: 'settings.assessmentbutton.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.CLINICAL_ASSESSMENT,
    },
    {
        id: 'tobaccoCessation',
        path: `${telemedPrefix}/information/tobacco_cessation`,
        isRelative: true,
        mixpanelEvent: 'settings.tobaccocessation.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.TOBACCO_CESSATION,
    },
    {
        id: 'expertMedicalOpinion',
        path: `${telemedPrefix}/expert_opinion`,
        isRelative: true,
        mixpanelEvent: 'settings.expertmedicalopinion.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.EXPERT_MEDICAL_OPINION,
    },
    {
        id: 'backCare',
        path: `${telemedPrefix}/saml/idp_initiated_sso/Orbittelehealth`,
        isRelative: true,
        mixpanelEvent: 'settings.backcare.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.BACK_CARE,
    },
    {
        id: 'sexualHealth',
        path: `${telemedPrefix}/information/lab_partner_testing`,
        isRelative: true,
        mixpanelEvent: 'settings.sexualhealth.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.SEXUAL_HEALTH,
    },
    {
        id: 'findBestDoctor',
        path: `${telemedPrefix}/find_best_doctor`,
        isRelative: true,
        mixpanelEvent: 'settings.findbestdoctor.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.FIND_BEST_DOCTOR,
    },
    {
        // TODO: Please double check the legacy web path for this flow
        id: 'careTeamSelect',
        path: `${telemedPrefix}/member/:id/mhd/care_team_select`,
        isRelative: true,
        mixpanelEvent: 'settings.careteamselect.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.CARE_TEAM_SELECT,
    },
    {
        // TODO: Please recheck the airtable for the websdk's PATH. websdkPath is missing
        id: 'mentalHealthNavigator',
        path: `${telemedPrefix}/mental_health_navigator`,
        isRelative: true,
        mixpanelEvent: 'settings.mentalhealthnavigator.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.MENTAL_HEALTH_NAVIGATOR,
    },
    {
        // TODO: Please recheck the airtable for the websdk's PATH. websdkPath is missing
        id: 'careRecipient',
        path: `${telemedPrefix}/care_recipients`,
        isRelative: true,
        mixpanelEvent: 'settings.carerecipient.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.CARE_RECIPIENTS,
    },
    {
        // TODO: Please recheck the airtable for the websdk's PATH. websdkPath is missing
        id: 'eliteDiagnosticImagingService',
        path: `${telemedPrefix}/elite_diagnostic_imaging_service`,
        isRelative: true,
        mixpanelEvent: 'settings.elitediagnosticimagingservice.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
        websdkPath: paths.ELITE_DIAGNOSTIC_IMAGING_SERVICE,
    },
    {
        // TODO: Please recheck the airtable for the websdk's PATH. websdkPath is missing
        id: 'personalHealthNavigator',
        path: `${telemedPrefix}/personal_health_navigator`,
        isRelative: true,
        mixpanelEvent: 'settings.personalhealthnavigator.cta.clicked',
        websdkPath: paths.PERSONAL_HEALTH_NAVIGATOR,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'videoWaitingRoom',
        path: `/video/:consultId`,
        isRelative: true,
        mixpanelEvent: 'settings.joinwaitingroom.cta.clicked',
        usedByMentalHealth: true,
        websdkPath: paths.JOIN_WAITING_ROOM,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'allGenericRavRequest',
        path: `${telemedPrefix}/consult/new`,
        mixpanelEvent: 'settings.allgenericravrequest.cta.clicked',
        isRelative: true,
        usedByMentalHealth: true,
        websdkPath: paths.RAV,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'hhsVisit',
        path: `${telemedPrefix}/hhs/video_quick_url`,
        isRelative: true,
        websdkPath: paths.WELCOME_PAGE,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        // TODO: Check for correct websdk path
        id: 'informationTelespinePhysicalTherapy',
        path: `${telemedPrefix}/information/telespine_physical_therapy`,
        isRelative: true,
        websdkPath: paths.TELESPINE_PHYSICAL_THERAPY,
        mixpanelEvent:
            'settings.informationtelespinephysicaltherapy.cta.clicked',
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'advisoryCaseStatus',
        path: `${telemedPrefix}/advisory_case_status`,
        mixpanelEvent: 'settings.advisorycasestatus.cta.clicked',
        isRelative: true,
        websdkPath: paths.CASES, // TO DO: Place holder and might not work for now
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'memberPastVisits',
        path: `${telemedPrefix}/member/:id/mhd/consultation_history`,
        mixpanelEvent: 'settings.memberpastvisits.cta.clicked',
        isRelative: true,
        websdkPath: paths.VISITS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'memberHealthRecord',
        path: `${telemedPrefix}/member/:id/mhd/health_record`, // Triggered by 24x7 RAV for dependent
        mixpanelEvent: 'settings.memberhealthrecord.cta.clicked',
        isRelative: true,
        websdkPath: paths.HEALTH, // TODO: Place holder. Recheck
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'wellCareCategory',
        path: `${telemedPrefix}/care_option/wellness_care_category`,
        mixpanelEvent: 'settings.wellcarecategory.cta.clicked',
        isRelative: true,
        websdkPath: paths.WELLNESS, // TODO: Place holder. Recheck
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'specialtyCareCategory',
        path: `${telemedPrefix}/care_option/specialty_care_category`,
        mixpanelEvent: 'settings.specialtycarecategory.cta.clicked',
        isRelative: true,
        websdkPath: paths.SPECIALTY_CARE, // TODO: Place holder. Recheck
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'consultEditId',
        path: `${telemedPrefix}/consult/:consultId/edit`,
        mixpanelEvent: 'settings.consulteditid.cta.clicked',
        isRelative: true,
        usedByMentalHealth: true,
        websdkPath: paths.RESCHEDULE_GM_VISIT, // TODO: Recheck to make sure. This is being used for mental health so be wary of changing this
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'consultAssessmentId',
        path: `${telemedPrefix}/consult/:consultId/assessment`,
        mixpanelEvent: 'settings.consultassessmentid.cta.clicked',
        isRelative: true,
        websdkPath: paths.CONSULT_ASSESSMENT,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'consultCancelId',
        path: `${telemedPrefix}/consult/:consultId/cancel`,
        mixpanelEvent: 'settings.consultcancelid.cta.clicked',
        isRelative: true,
        websdkPath: paths.VISIT_CANCELLATION,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'checklistConsultAssessmentId',
        path: `${telemedPrefix}/consult/:consultationId/checklist`,
        mixpanelEvent: 'settings.checklistConsultAssessmentId.cta.clicked',
        isRelative: true,
        websdkPath: paths.CHECKLIST,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'messageInbox',
        path: `${telemedPrefix}/care_team_messages/:messageId?`,
        mixpanelEvent: 'settings.messageinbox.cta.clicked',
        isRelative: true,
        websdkPath: paths.INBOX, // TODO: Recheck to make sure
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'costOverView',
        path: `${telemedPrefix}/my_account/pricing_table`,
        mixpanelEvent: 'settings.costoverview.cta.clicked',
        isRelative: true,
        websdkPath: paths.PRICING_TABLE,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'generalMedicalMessage',
        path: `${telemedPrefix}/consult/:generalMessageId/messages`, // Triggered from homepage sidepanel.
        mixpanelEvent: 'navigation.generalmedicalmessage.cta.clicked',
        isRelative: true,
        websdkPath: paths.GENERAL_MESSAGE, // TODO: This is a placeholder. It does not work.
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'helpContact',
        path: `${telemedPrefix}/help/contact`,
        mixpanelEvent: 'navigation.helpcontact.cta.clicked',
        isRelative: true,
        websdkPath: paths.HELP,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'doctorsAndPharmacies',
        path: `${telemedPrefix}/member/:id/mhd/doctors_and_pharmacies`,
        mixpanelEvent: 'navigation.doctorsandpharmacies.cta.clicked',
        isRelative: true,
        websdkPath: paths.OTHER_PROVIDERS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'securityQuestions',
        path: `${accountPrefix}/security-questions`,
        mixpanelEvent: 'navigation.securityquestions.screen.loaded',
        isRelative: true,
        websdkPath: paths.SECURITY_QUESTIONS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'medicalHistoryAfterReg',
        path: `${healthInfoPrefix}/medical-history-after-reg`,
        mixpanelEvent: 'navigation.medicalhistoryafterreg.screen.loaded',
        isRelative: true,
        websdkPath: paths.HEALTH_AFTER_REG,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'wellnessContent',
        path: '/wellness-content',
        isRelative: true,
        icon: null,
        websdkPath: paths.WELLNESS_CONTENT,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'labScheduling',
        path: `${telemedPrefix}/lab_scheduling`,
        mixpanelEvent: 'settings.labScheduling.cta.clicked',
        isRelative: true,
        websdkPath: paths.LAB_SCHEDULING,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'vendorLandingPage',
        path: '/vendor_superhighway/vendor_landing',
        isRelative: true,
        icon: null,
        websdkPath: paths.VENDOR_LANDING,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'vendorTransfer',
        path: '/vendor_management/transfer',
        isRelative: true,
        icon: null,
        websdkPath: paths.VENDOR_TRANSFER_PAGE,
        component: lazy(() => import('@/app/Websdk')),
    },
];

export const mentalHealthWebviewRoutes = [
    {
        id: 'mentalHealthHome',
        path: `${MENTAL_HEALTH_PREFIX}/home`,
        isRelative: true,
        addToSidebar: true,
        featureFlagDisable: 'mhWebsdk',
        component: lazy(() => import('@/mental-health/MentalHealth')),
    },
    {
        id: 'mentalHealthConnect',
        path: `${MENTAL_HEALTH_PREFIX}/connect`,
        isRelative: true,
        addToSidebar: true,
        featureFlagDisable: 'mhWebsdk',
        component: lazy(() => import('@/mental-health/MentalHealth')),
    },
    {
        id: 'mentalHealthGuide',
        path: `${MENTAL_HEALTH_PREFIX}/guide`,
        isRelative: true,
        addToSidebar: true,
        featureFlagDisable: 'mhWebsdk',
        component: lazy(() => import('@/mental-health/MentalHealth')),
    },
    {
        id: 'mentalHealthCrisis',
        path: `${MENTAL_HEALTH_PREFIX}/crisis`,
        isRelative: true,
        featureFlagDisable: 'mhWebsdk',
        component: lazy(() => import('@/mental-health/MentalHealth')),
    },
    {
        id: 'mentalHealthExplore',
        path: `${MENTAL_HEALTH_PREFIX}/explore`,
        isRelative: true,
        addToSidebar: true,
        featureFlagDisable: 'mhWebsdk',
        component: lazy(() => import('@/mental-health/MentalHealth')),
    },
    {
        id: 'mentalHealthPersonalization',
        path: `${MENTAL_HEALTH_PREFIX}/profile/personalization`,
        isRelative: true,
        addToSidebar: true,
        featureFlagDisable: 'mhWebsdk',
        component: lazy(() => import('@/mental-health/MentalHealth')),
    },
    {
        id: 'mentalHealthResources',
        path: `${MENTAL_HEALTH_PREFIX}/help-resources`,
        isRelative: true,
        addToSidebar: true,
        featureFlagDisable: 'mhWebsdk',
        component: lazy(() => import('@/mental-health/MentalHealth')),
    },
    {
        id: 'mentalHealthHistory',
        path: `${MENTAL_HEALTH_PREFIX}/profile/history`,
        isRelative: true,
        addToSidebar: true,
        featureFlagDisable: 'mhWebsdk',
        component: lazy(() => import('@/mental-health/MentalHealth')),
    },
    {
        id: 'mentalHealthBase',
        path: `${MENTAL_HEALTH_PREFIX}`,
        isRelative: true,
        featureFlagDisable: 'mhWebsdk',
        component: lazy(() => import('@/mental-health/MentalHealth')),
    },
];

export const mentalHealthWebsdkRoutes = [
    {
        id: 'mentalHealthHome',
        path: `${MENTAL_HEALTH_PREFIX}/home`,
        isRelative: true,
        addToSidebar: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'home',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthConnect',
        path: `${MENTAL_HEALTH_PREFIX}/connect`,
        isRelative: true,
        addToSidebar: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'connect',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthGuide',
        path: `${MENTAL_HEALTH_PREFIX}/guide`,
        isRelative: true,
        addToSidebar: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'guide',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthCrisis',
        path: `${MENTAL_HEALTH_PREFIX}/crisis`,
        isRelative: true,
        featureFlagEnable: 'mhWebsdk',
        websdkPath: paths.MYSTRENGTH_CRISIS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthExplore',
        path: `${MENTAL_HEALTH_PREFIX}/explore`,
        isRelative: true,
        addToSidebar: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'explore',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthProgress',
        path: `${MENTAL_HEALTH_PREFIX}/progress`,
        isRelative: true,
        addToSidebar: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'progress',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthPersonalization',
        path: `${MENTAL_HEALTH_PREFIX}/profile/personalization`,
        isRelative: true,
        addToSidebar: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'content_preferences',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthResources',
        path: `${MENTAL_HEALTH_PREFIX}/help-resources`,
        isRelative: true,
        addToSidebar: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'get_help',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthHistory',
        path: `${MENTAL_HEALTH_PREFIX}/profile/history`,
        isRelative: true,
        addToSidebar: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'history',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthSleepTracker',
        path: `${MENTAL_HEALTH_PREFIX}/sleep`,
        isRelative: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'sleep_home',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthSleepTracker',
        path: `${MENTAL_HEALTH_PREFIX}/diary-entries`,
        isRelative: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'diary_entries',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthActivityHistory',
        path: `${MENTAL_HEALTH_PREFIX}/activity/history`,
        isRelative: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'activity_history',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthActivity',
        path: `${MENTAL_HEALTH_PREFIX}/activity`,
        isRelative: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'activity',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthOnboarding',
        path: `${MENTAL_HEALTH_PREFIX}/onboarding`,
        isRelative: true,
        featureFlagEnable: 'mhWebsdk',
        websdkPath: paths.MYSTRENGTH_ONBOARDING,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthDeeplink',
        path: `${MENTAL_HEALTH_PREFIX}/dl`,
        isRelative: true,
        isDeepLink: true,
        featureFlagEnable: 'mhWebsdk',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'mentalHealthReassessment',
        path: `${MENTAL_HEALTH_PREFIX}/reassessment`,
        isRelative: true,
        featureFlagEnable: 'mhWebsdk',
        destination: 'reassesment',
        websdkPath: paths.MYSTRENGTH_DEEPLINKS,
        component: lazy(() => import('@/app/Websdk')),
    },
];

export const commonRoutes = [
    {
        id: 'sitemap',
        path: '/sitemap',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../sitemap/Sitemap')),
    },
    {
        id: 'login',
        path: '/login',
        isRelative: true,
        icon: null,
    },
    {
        id: 'inaccessible',
        path: '/inaccessible',
        isRelative: true,
        icon: null,
        component: lazy(() => import('./Inaccessible')),
    },
];

export const selfReportedRoutes = [
    {
        id: 'a1c-report',
        path: `${CONDITION_MANAGEMENT_PREFIX}/a1c-report`,
        mixpanelEvent: 'settings.nudge.a1c.clicked',
        isRelative: true,
        apptentiveEvent: true,
        programSlugs: [bloodSugar.slug],
        component: lazy(() => import('../self-reported-screens/a1c/A1cReport')),
    },
    {
        id: 'cholesterol-report',
        path: `${CONDITION_MANAGEMENT_PREFIX}/cholesterol-report`,
        mixpanelEvent: 'settings.nudge.cholesterol.clicked',
        isRelative: true,
        apptentiveEvent: true,
        programSlugs: [bloodSugar.slug],
        component: lazy(() =>
            import('../self-reported-screens/cholesterol/CholesterolReport')
        ),
    },
    {
        id: 'a1c-cholesterol-combined-report',
        path: `${CONDITION_MANAGEMENT_PREFIX}/a1c-cholesterol-combined-report`,
        mixpanelEvent: 'settings.nudge.a1c-cholesterol.clicked',
        isRelative: true,
        apptentiveEvent: true,
        programSlugs: [bloodSugar.slug],
        component: lazy(() =>
            import('../self-reported-screens/A1cCholesterolCombinedReport')
        ),
    },
];

export const quickLinksRoutes = [
    {
        id: 'oneAppReportsAndData',
        path: `${CONDITION_MANAGEMENT_PREFIX}/reports-and-data`,
        mixpanelEvent: 'settings.summaryreports.cta.clicked',
        navIcon: IconDocumentDefault,
        programSlugs: [
            bloodSugar.slug,
            deviceFlex.slug,
            bloodPressure.slug,
            heartFailure.slug,
            weight.slug,
            advancedWeight.slug,
            comprehensiveWeight.slug,
            prediabetes.slug,
            advancedPrediabetes.slug,
            comprehensivePrediabetes.slug,
            chronicKidneyDisease.slug,
        ],
        isRelative: true,
        component: lazy(() =>
            import('@teladoc/fe-ccm/ui/reports-and-data/ReportsAndData')
        ),
    },
    {
        id: 'oneAppOrderSupplies',
        path: `${CONDITION_MANAGEMENT_PREFIX}/order-supplies`,
        mixpanelEvent: 'settings.ordersupplies.cta.clicked',
        navIcon: IconSuppliesDefault,
        programSlugs: [bloodSugar.slug],
        isRelative: true,
        component: lazy(() =>
            import('@teladoc/fe-ccm/ui/order-supplies/OrderSupplies')
        ),
    },
    {
        id: 'oneAppUserGuides',
        path: `${CONDITION_MANAGEMENT_PREFIX}/user-guides`,
        mixpanelEvent: 'settings.userguides.cta.clicked',
        navIcon: IconDeviceDefault,
        programSlugs: [
            bloodSugar.slug,
            bloodPressure.slug,
            heartFailure.slug,
            weight.slug,
            advancedWeight.slug,
            comprehensiveWeight.slug,
            prediabetes.slug,
            advancedPrediabetes.slug,
            comprehensivePrediabetes.slug,
            chronicKidneyDisease.slug,
        ],
        isRelative: true,
        icon: IconDocumentDefault,
        component: lazy(() =>
            import('@teladoc/fe-ccm/ui/user-guides/UserGuides')
        ),
    },
];

// These routes appear on Message menu in Header
// TODO: remove websdkPath from messageTelemed route, add it to the messageCenterRoutes instead
export const messageRoutes = [
    {
        id: 'messageTelemed',
        path: `${telemedPrefix}/inbox/:inboxId?`,
        isRelative: true,
        isHorizontal: true,
        icon: IconGroupActive,
        usedByMentalHealth: true,
        websdkPath: paths.INBOX,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'messageConditionManagement',
        path: `${messagesPrefix}/condition-management`,
        isRelative: true,
        isHorizontal: true,
        icon: IconHeartPulseColor,
    },
    {
        id: 'messageMentalHealth',
        path: `${messagesPrefix}/mental-health`,
        isRelative: true,
        isHorizontal: false,
        icon: IconBehavioralHealthColor,
    },
];

// These routes appear on Message Center as tabs
export const messageCenterRoutes = [
    {
        id: INBOX_SERVICE_TYPES.CareTeam,
        path: INBOX_ROUTES[INBOX_SERVICE_TYPES.CareTeam],
        websdkPath: paths.INBOX,
        component: Telemed,
    },
    {
        id: INBOX_SERVICE_TYPES.ChronicCare,
        path: INBOX_ROUTES[INBOX_SERVICE_TYPES.ChronicCare],
        component: ConditionManagement,
    },
    {
        id: INBOX_SERVICE_TYPES.MentalHealth,
        path: INBOX_ROUTES[INBOX_SERVICE_TYPES.MentalHealth],
        websdkPath: paths.MYSTRENGTH_GUIDES,
        component: MentalHealth,
    },
];

// These routes appear on Account menu in Header
export const accountRoutes = [
    {
        id: 'profilePreferences',
        path: `${accountPrefix}/profile`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Profile',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.Profile,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconProfileDefault,
        content: 'profileContent',
        websdkPath: paths.BASIC_PROFILE,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'security',
        path: `${accountPrefix}/security-and-signin`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Security and sign in',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.SecuritySignIn,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconLockDefault,
        websdkPath: paths.SECURITY_AND_SIGN_IN,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'communication',
        path: `${accountPrefix}/communication-preferences`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Communication preferences',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.CommPreferences,
        },
        apptentiveEvent: true,
        isRelative: true,
        icon: IconReminderDefault,
        isHorizontal: false,
        content: 'communicationContent',
        websdkPath: paths.COMMUNICATION_PREFERENCES,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'emergency',
        path: `${accountPrefix}/emergency-contacts`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Emergency contacts',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.EmergencyContacts,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconEmergencyContactDefault,
        content: 'emergencyContent',
        websdkPath: paths.EMERGENCY_CONTACT,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'family',
        path: `${accountPrefix}/family`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Family',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.Family,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconFamilyDefault,
        content: 'familyContent',
        websdkPath: paths.MY_FAMILY,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'coverage',
        path: `${accountPrefix}/coverage-and-billing`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Billing',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.BillingCoverage,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: true,
        icon: IconCreditCardDefault,
        websdkPath: paths.COVERAGE_AND_BILLING,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'getHelp',
        path: `${accountPrefix}/get-help`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Get help',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.GetHelp,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: true,
        icon: IconHelpDefault,
        websdkPath: paths.HELP,
        component: lazy(() => import('@/app/Websdk')),
    },
];

export const logoutRoute = [
    {
        id: 'logout',
        path: '/logout',
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Sign out',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.SignOut,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconSignOutDefault,
    },
];

// These routes appear on Health Info menu in Header
export const healthMenuRoutes = [
    {
        id: 'visits',
        path: '/member/mhd/consultation_history',
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Past telehealth visits',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.PastTeleVisits,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconCalendarDefault,
        websdkPath: paths.VISITS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'documents',
        path: `${healthInfoPrefix}/documents`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Telehealth visit documents',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.TeleDocuments,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconAttachmentDefault,
        websdkPath: paths.IMAGES_AND_DOCUMENTS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'medicalHistory',
        path: `${healthInfoPrefix}/medical-history`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Medical history',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.MedicalHistory,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconChecklistDefault,
        usedByMentalHealth: true,
        websdkPath: paths.HEALTH,
        content: 'medicalContent',
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'careTeam',
        path: `${healthInfoPrefix}/primary-care`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Primary Care team',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.PrimaryCare,
            level: 2,
            button: EVENT_PROPS.PrimaryCareTeam,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconPrimaryCareDefault,
        websdkPath: paths.CARE_TEAM,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'carePlan',
        path: `${healthInfoPrefix}/primary-care-plan`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Primary Care plan',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.PrimaryCare,
            level: 2,
            button: EVENT_PROPS.PrimaryCarePlan,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconMedicalInfoDefault,
        websdkPath: paths.CARE_PLAN,
        component: lazy(() => import('@/app/Websdk')),
    },
];

export const telemedHealthInfoRoute = [
    {
        id: 'vitals',
        path: `${healthInfoPrefix}/vitals`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Primary Care vitals',
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconHeartPulseDefault,
        websdkPath: paths.VITALS,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'expertMedical',
        path: `${healthInfoPrefix}/your-cases`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Expert Medical Opinion requests',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.ExpertMedicalOpinion,
            level: 2,
            button: EVENT_PROPS.ExpertMedRequests,
        },
        apptentiveEvent: true,
        isRelative: true,
        icon: IconDocumentDefault,
        isHorizontal: false,
        websdkPath: paths.CASES,
        content: 'expertMedicalContent',
        component: lazy(() => import('@/app/Websdk')),
    },
];

export const pharmacyRoutes = [
    {
        id: 'pharmacy',
        path: `${healthInfoPrefix}/pharmacies`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Pharmacies',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.Pharmacies,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconMedicineDefault,
        websdkPath: paths.PHARMACIES,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'externalProviders',
        path: `${healthInfoPrefix}/other-providers`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'External providers',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 2,
            button: EVENT_PROPS.ExternalProviders,
        },
        apptentiveEvent: true,
        isRelative: true,
        isHorizontal: false,
        icon: IconGroupDefault,
        websdkPath: paths.OTHER_PROVIDERS,
        component: lazy(() => import('@/app/Websdk')),
    },
];

export const healthRiskAssessmentRoutes = [
    {
        id: 'healthriskAssessment',
        path: `/health_check`,
        isRelative: true,
        websdkPath: paths.HEALTH_RISK_ASSESSMENT,
        component: lazy(() => import('@/app/Websdk')),
    },
];

export const healthInfoRoutes = [
    ...healthMenuRoutes,
    ...telemedHealthInfoRoute,
    ...pharmacyRoutes,
];

export const navRoutes = [
    {
        id: NAV_MENU.Home.id,
        path: '/home',
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 1,
            button: 'Home',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 1,
            button: EVENT_PROPS.Home,
        },
        apptentiveEvent: true,
        isRelative: true,
        icon: IconHomeDefault,
        activeIcon: IconHomeActive,
        isHorizontal: false,
        websdkPath: paths.DFD_DASHBOARD,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: NAV_MENU.Programs.id,
        path: '/program',
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 1,
            button: 'Programs',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 1,
            button: EVENT_PROPS.Programs,
        },
        apptentiveEvent: true,
        isRelative: true,
        icon: IconProgramsDefault,
        activeIcon: IconProgramsActive,
        isHorizontal: false,
        subNavRoutes: programRoutes,
        component: lazy(() => import('@/Navigation/Navigation')),
    },
    {
        id: NAV_MENU.HealthInfo.id,
        path: `${healthInfoPrefix}`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 1,
            button: 'Health Info',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 1,
            button: EVENT_PROPS.HealthInfo,
        },
        apptentiveEvent: true,
        isRelative: true,
        icon: IconHealthInfoDefault,
        activeIcon: IconHealthInfoActive,
        isHorizontal: false,
        subNavRoutes: healthInfoRoutes,
        component: lazy(() => import('@/Navigation/Navigation')),
    },
    {
        id: NAV_MENU.Message.id,
        path: `${messagesPrefix}`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 1,
            button: 'Messages',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 1,
            button: EVENT_PROPS.Messages,
        },
        apptentiveEvent: true,
        isRelative: true,
        icon: IconChatDefault,
        activeIcon: IconChatActive,
        isHorizontal: false,
        subNavRoutes: messageRoutes,
        component: lazy(() => import('@/Messages/index')),
    },
    {
        id: NAV_MENU.MessageCenter.id,
        path: '/message-center',
        onlyWebRoute: true,
        subNavRoutes: messageCenterRoutes,
        component: lazy(() => import('@/message-center/MessageCenter')),
    },
    {
        id: NAV_MENU.Account.id,
        path: `${accountPrefix}`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 1,
            button: 'Account',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.Global,
            level: 1,
            button: EVENT_PROPS.Account,
        },
        apptentiveEvent: true,
        isRelative: true,
        icon: IconProfileDefault,
        activeIcon: IconProfileActive,
        isHorizontal: false,
        subNavRoutes: accountRoutes,
        component: lazy(() => import('@/Navigation/Navigation')),
    },
];

export const redirectRoutes = [
    {
        id: 'ccmSso',
        exact: true,
        path: '/ccm-sso',
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Condition Management SSO',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.CCM,
            level: 2,
            button: EVENT_PROPS.ConditionManagement,
        },
        icon: IconHeartPulseColor,
        isHorizontal: true,
        isRelative: true,
        websdkPath: paths.CCM_SSO,
        component: lazy(() => import('@/app/Websdk')),
    },
    {
        id: 'ccmProgramsRedirect',
        path: `${CONDITION_MANAGEMENT_PREFIX}/programs`,
        mixpanelEvent: 'navigation.button.clicked',
        mixpanelProperty: {
            level: 2,
            button: 'Condition Management Programs',
        },
        amplitudeProperty: {
            productLine: EVENT_PROPS.CCM,
            level: 2,
            button: EVENT_PROPS.ConditionManagement,
        },
        programSlugs: [],
        isRelative: true,
        icon: IconHeartPulseColor,
        component: lazy(() => import('@teladoc/fe-ccm/ui/programs/Programs')),
    },
];

export const subNavRoutes = [...quickLinksRoutes, ...secondaryCCMNavRoutes];

// It's important to note that the order matters here. The router looks for routes that
// match in the order it receives them. For example, we need to keep programRoutes before
// mentalHealthRoutes in the order here so the router will still pick up 'mentalHealth'
// (the interstitial page) before it picks up 'mentalHealthBase' (the mental health
// wildcard route). Any changes to this order or the order of individual routes within
// their respective groupings should be tested thoroughly.

export const mainRoutes = [
    ...programRoutes,
    ...quickLinksRoutes,
    ...commonRoutes,
    ...messageRoutes,
    ...accountRoutes,
    ...telemedRoutes,
    ...mentalHealthWebviewRoutes,
    ...mentalHealthWebsdkRoutes,
    ...healthInfoRoutes,
    ...subNavRoutes,
    ...redirectRoutes,
    ...otherCCMRoutes,
    ...healthRiskAssessmentRoutes,
    ...selfReportedRoutes,
];

export function getPrimaryRouteById(id) {
    const match = [...mainRoutes, ...navRoutes, ...messageCenterRoutes].find(
        route => route.id === id
    );

    if (!match) {
        throw new Error(`No primary route exists for ${id}.`);
    }

    return match;
}

export function getSecondaryRoute({primaryId, secondaryId}) {
    const {tabbedRoutes = [], routes = []} = getPrimaryRouteById(primaryId);
    const match = [...tabbedRoutes, ...routes].find(
        route => route.id === secondaryId
    );

    if (!match) {
        throw new Error(`No secondary route exists for ${secondaryId}.`);
    }

    return match;
}

export function getCommonRouteById(id) {
    const match = commonRoutes.find(route => route.id === id);

    if (!match) {
        throw new Error(`No common route exists for ${id}.`);
    }

    return match;
}

export function getMHRouteByWebsdkPath(websdkPath) {
    const match = getWebsdkRoutes().filter(route => {
        return route.usedByMentalHealth && route.websdkPath === websdkPath;
    });

    // These validations are here because we don't have a good way of guaranteeing that each
    // route will have its own WebSDK path, and that each WebSDK path that we are looking for
    // actually exists. These are here so developers will see errors in the console logs if
    // they violate these conditions.
    if (!match || match.length === 0) {
        throw new Error(`No route exists for websdkPath '${websdkPath}'.`);
    }

    if (match.length > 1) {
        throw new Error(
            `Multiple routes exists for websdkPath '${websdkPath}'.`
        );
    }

    return match[0];
}

export function getActiveRoutes(routes, hasKidneyLabResults = false) {
    return reduce(
        routes,
        (result, route) => {
            const isKidneyHealth =
                route.id === 'kidneyHealth' && hasKidneyLabResults;

            if (route.id === 'kidneyHealth' && !hasKidneyLabResults) {
                // We don't want to show "Kidney Health" on the sidebar if there's no kidney lab results
                return result;
            } else if (
                isKidneyHealth ||
                UserUtils.willRender(route.programSlugs)
            ) {
                result.push(route);
            }

            return result;
        },
        []
    );
}

export function getWebsdkRoutes() {
    return [...mainRoutes, ...navRoutes, ...messageCenterRoutes].filter(
        route => route.websdkPath
    );
}
