// This is from the member.teladoc.com util to help build the websdkconfig
import {MEDOPT_SIGNUP} from '@teladoc/fe-ccm/ui/config';
import OneAppUserAPI from '@/user/user-oneapp-api';

export const getUrlParameter = name => {
    const nameEscaped = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${nameEscaped}=([^&#]*)`);
    const results = regex.exec(window.location.search);

    return results === null
        ? null
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

// TODO: This should probably be integrated into getCurrentUrlWithAddedParams
// so they are handled consistently. This would be the generalized case and
// getCurrentUrlWithAddedParams would be the specific case where your base
// URL is the current window location
export const addParamToUrl = (url, param) => {
    if (url.includes('?')) {
        return `${url}&${param}`;
    }

    return `${url}?${param}`;
};

export const getCurrentUrlWithAddedParams = newParams => {
    const pathname = window.location.pathname;
    const query = window.location.search;

    if (Object.keys(newParams).length === 0) {
        return pathname + query;
    }

    const newParamsQuery = Object.entries(newParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    const newQuery = query
        ? `${query}&${newParamsQuery}`
        : `?${newParamsQuery}`;

    return pathname + newQuery;
};

export const prependProtocol = link => {
    const regex = /^https?:\/\/|^\/\//i;

    if (!regex.test(link)) {
        return `https://${link}`;
    }

    return link;
};

/* Use for URLs with the following formats. !!Note that PREFIXES with hyphens will cause this to break!!

AWS: PREFIX.NAMESPACE.CLUSTER.DOMAIN
e.g. my.devsd11572.qa.teladoc.io

Azure:
PREFIX-NAMESPACE.VISIBILITY.CLUSTER.DOMAIN
e.g. my-devsd11572.private.qa.tdoctest.com
*/
export const getCurrentUrlPrefix = () => {
    return window.location.host.split(/[.-]/, 1)[0];
};

/* returns NAMESPACE.CLUSTER.DOMAIN from -
AWS: PREFIX.NAMESPACE.CLUSTER.DOMAIN
Azure: PREFIX-NAMESPACE.VISIBILITY.CLUSTER.DOMAIN

Note that this function returns a value with either a period or hyphen as the first character,
depending on which cloud provider this is hosted on. The character is left on for ease of
combining back into a full host URL, as seen in replacePrefixWith() below. If you want to
take this and separate it out into the remaining nodes, please see getNodesAfterPrefix() below
*/
export const getCurrentUrlHostWithoutPrefix = () => {
    const prefixLength = getCurrentUrlPrefix().length;

    return window.location.host.substring(prefixLength);
};

/* returns individual nodes (aside from prefix) from URL as an array:
AWS: [NAMESPACE, CLUSTER, DOMAIN]
Azure: [NAMESPACE, VISIBILITY, CLUSTER, DOMAIN]

While this is currently not being used, it is provided here to show that aside from the
separation between prefix and namespace, the other node boundaries should still be
separated by periods. The (1) is needed because getCurrentUrlHostWihoutPrefix above
returns a value with a period or hyphen on the front
*/
export const getNodesAfterPrefix = () => {
    return getCurrentUrlHostWithoutPrefix().substring(1).split('.');
};

export const replaceCurrentPrefixWith = updatedPrefix => {
    return `https://${updatedPrefix}${getCurrentUrlHostWithoutPrefix()}`;
};

export const getUpdatedWebsdkURL = country => {
    if (
        process.env.ENVIRONMENT === 'production' ||
        process.env.ENVIRONMENT === 'local'
    ) {
        return country === 'CA'
            ? process.env.WEBSDK_BASE_URL_CA
            : process.env.WEBSDK_BASE_URL;
    } else {
        return replaceCurrentPrefixWith('mobile-api');
    }
};

export const redirectToMedoptLoginPage = () => {
    if (process.env.ENVIRONMENT === 'local') {
        window.location.href = `${process.env.LOGIN_REDIRECT}/${MEDOPT_SIGNUP}`;
    } else {
        window.location.href = `${replaceCurrentPrefixWith(
            'member'
        )}/${MEDOPT_SIGNUP}`;
    }
};

export const redirectToLoginPage = (isLogout, memberHost) => {
    const params = window.location.search;
    let path = window.location.pathname;

    if (params) {
        path = path.concat(params);
    }

    if (path?.includes(MEDOPT_SIGNUP)) {
        redirectToMedoptLoginPage();
    } else if (process.env.ENVIRONMENT === 'local') {
        // This is for local environment only so DEVs don't have to manually go to
        // the login page to set the cookies.
        window.location.href = `${process.env.LOGIN_REDIRECT}/${
            !isLogout ? '/' : 'logout'
        }${!isLogout ? path : ''}`;
    } else if (memberHost && OneAppUserAPI.isPLIBrand()) {
        window.location.href = `${memberHost}/${!isLogout ? '/' : 'logout'}${
            !isLogout ? path : ''
        }`;
    } else {
        window.location.href = `${replaceCurrentPrefixWith('member')}/${
            !isLogout ? '/' : 'logout'
        }${!isLogout ? path : ''}`;
    }
};

// Redirect URLs used in the Telapp Member App to the OneApp versions
export const redirectMemberAppUrls = pathname => {
    if (!pathname) {
        return;
    }

    let updatedPath;

    if (pathname.includes('my_account/personal_details')) {
        updatedPath = '/account/profile';
    } else if (pathname.includes('my_account/emergency_contact')) {
        updatedPath = '/account/emergency-contacts';
    } else if (pathname.includes('my_family/overview')) {
        updatedPath = '/account/family';
    } else if (pathname.includes('my_account/login_information')) {
        updatedPath = '/account/security-questions';
    }

    if (updatedPath) {
        window.history.pushState({}, '', updatedPath);
    }
};

const UrlUtils = {
    addParamToUrl,
    getUrlParameter,
    getCurrentUrlWithAddedParams,
    prependProtocol,
    getCurrentUrlPrefix,
    getCurrentUrlHostWithoutPrefix,
    getNodesAfterPrefix,
    replaceCurrentPrefixWith,
    getUpdatedWebsdkURL,
    redirectToLoginPage,
    redirectToMedoptLoginPage,
    redirectMemberAppUrls,
};

export default UrlUtils;
