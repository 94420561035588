import 'index.scss';
import {render} from 'react-dom';
import {Provider, useDispatch} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Loader from '@teladoc/fe-ccm/ui/loader/Loader';
import mixpanel from 'mixpanel-browser';
import {PersistGate} from 'redux-persist/integration/react';
import ErrorBoundary from '@teladoc/pulse/ui/ErrorBoundary';
import PulseApp, {STATUSES} from '@teladoc/pulse/ui/App';
import ApptentiveUtils from '@teladoc/fe-ccm/ui/common/apptentive/apptentive-utils';
import createStore from '@teladoc/fe-ccm/ui/store';
import UserAPI from '@teladoc/fe-ccm/ui/user/user-api';
import ChatAPI from '@teladoc/fe-ccm/ui/chat/chat-api';
import {config as i18n} from '@teladoc/fe-i18n';
import {useEffect, useState} from 'react';
import {userUpdate} from '@teladoc/fe-ccm/ui/user/user-actions';
import {appUpdate} from '@teladoc/fe-ccm/ui/app/app-actions';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import EnvironmentUtils from '@livongo/utilities/system/environment';
import SentryUtils, {DEFAULT_OPTIONS} from '@livongo/utilities/system/sentry';
import StorageUtils from '@livongo/utilities/system/storage';
import APIUtils from '@livongo/utilities/system/api';
import {FeatureFlagProvider} from '@livongo/utilities/system/featureFlag';
import {
    getPrimaryRouteById,
    CONDITION_MANAGEMENT_PREFIX,
} from '@/router/routes';
import create from '@/store';
import Unavailable from '@/router/Unavailable';
import Root from '@/app/Root';
import {redirectToLoginPage} from '@/utils/url-utils';
import PIWIKUtils from '@/utils/piwik-utils';
import OneAppUserAPI from '@/user/user-oneapp-api';
import CommonUtils from '@/utils/common-utils';
import {
    IS_PROD,
    NAV_MENU,
    MEMBER_ID,
    IMPERSONATION,
    PIWIK_ENABLED,
    MEMBER_ID_PREV,
} from '@/config';

const brandColor = OneAppUserAPI.getBrandColor();

if (brandColor) {
    const observer = new MutationObserver((mutations, observerInstance) => {
        const rootElement = document.querySelector(
            ':root[data-theme="pli"][data-mode="light"]'
        );

        if (rootElement) {
            rootElement.style.setProperty(
                '--color-brand-primary3',
                `${brandColor}`
            );
            observerInstance.disconnect();
        }
    });

    observer.observe(document.documentElement, {
        childList: true,
        subtree: true,
    });
}

const featureFlags = require(`@teladoc/fe-feature-flags/packages/environment/${
    process.env.ENVIRONMENT === 'local'
        ? 'integration'
        : process.env.ENVIRONMENT
}.json`);
const {
    loadingText,
} = require(`@teladoc/fe-i18n/locales/${CommonUtils.getOneAppLocaleCookie()}/app.json`);

const IS_DEV = process.env.NODE_ENV === 'development';
const promises = [];
const {persistor} = create();

APIUtils.init({
    baseUrl: process.env.API_URL,
    additionalHeaders: {
        'Content-Type': 'application/json',
        'Accept-Language': CommonUtils.getOneAppLocaleCookie(),
    },
});

// PIWIK Initialization scripts
if (IS_PROD || CommonUtils.getCookie({key: PIWIK_ENABLED})) {
    PIWIKUtils.init(window, document, process.env.PIWIK_SITE_ID);
    PIWIKUtils.loadScript(process.env.PIWIK_SITE_ID);
}

ChatAPI.init(process.env.SENDBIRD_APP_ID);

mixpanel.init(process.env.MIXPANEL_TOKEN);

if (isNil(OneAppUserAPI.getTdocAuthToken())) {
    redirectToLoginPage();
} else {
    if (document.location.pathname === '/') {
        window.history.pushState(
            {},
            '',
            getPrimaryRouteById(NAV_MENU.Home.id).path
        );
    }

    const impersonationDetails = OneAppUserAPI.getImpersonationDetails();

    if (impersonationDetails?.impersonatorId) {
        MixpanelUtils.setSuperProps({
            impersonation: true,
        });

        StorageUtils.set({
            key: IMPERSONATION,
            value: impersonationDetails,
            type: 'session',
            useNative: true,
        });
    } else {
        MixpanelUtils.setSuperProps({
            impersonation: false,
        });
    }

    if (
        OneAppUserAPI.getTdocAuthToken() !==
        OneAppUserAPI.getPrevMemberTdocAuthToken()
    ) {
        OneAppUserAPI.removeOneAppCookies();
    }

    if (
        isNil(OneAppUserAPI.getCurrentCcmAccessToken()) || // case 1 - no ccm token set
        (OneAppUserAPI.getCurrentCcmAccessToken() && // case 2 - ccm token set and ttl is NOT valid
            !OneAppUserAPI.isCcmTokenTtlValid()) ||
        CommonUtils.getLocalStorage(MEMBER_ID_PREV) !== // case 3 - diff member id
            CommonUtils.getCookie({key: MEMBER_ID})
    ) {
        promises.push(
            OneAppUserAPI.initCcmFlow().then(response => {
                return (
                    response &&
                    UserAPI.loadInitialDataForOneApp({
                        response,
                        isOneApp: true,
                        isAuthenticated: !isEmpty(
                            OneAppUserAPI.getTdocAuthToken()
                        ),
                        impersonationInfo: impersonationDetails,
                    })
                );
            })
        );
    } else {
        OneAppUserAPI.loadInitialData(promises, impersonationDetails);
    }
}

SentryUtils.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.BUILD_TAG,
    environment: process.env.ENVIRONMENT,
    whitelistUrls: [/my\.teladoc\.com/],
    ignoreErrors: [
        ...DEFAULT_OPTIONS.ignoreErrors,
        // the source of this issue is external to this project and it wasn't identified
        "undefined is not an object (evaluating 'window.webkit.messageHandlers*postMessage')",
        // axe-core issue
        `t.getAttribute is not a function. (In 't.getAttribute("role")', 't.getAttribute' is undefined)`,
        // Microsoft Outlook SafeLink crawler issue
        'Non-Error promise rejection captured',
    ],
});

if (EnvironmentUtils.isSafari()) {
    // Safari is the new IE, we need to be able to target it directly to fix random annoying issues
    document.querySelector('html')?.classList.add('Safari');
}

// Apptentive initialization is being done separately in case
// the Apptentive SDK fails to load, it won't bring down the whole site
// it is intentional to add to promises array
// as we do NOT want to trigger STATUSES.INITIALIZED in the AppUi render method
// untill Apptentive successfully resolves or fails to resolve
promises.push(
    new Promise((resolve, reject) => {
        (async () => {
            try {
                await ApptentiveUtils.init({
                    appId: process.env.APPTENTIVE_APP_ID,
                    options: {
                        // eslint-disable-next-line camelcase
                        app_release: {
                            version: process.env.VERSION,
                        },
                    },
                });
            } catch (error) {
                if (IS_DEV) {
                    // eslint-disable-next-line no-console
                    console.log('[ERROR: APPTENTIVE]', error);
                }
            }
            // even if Apptentive fails, resolve this promise as a success with no data in the resolve arg
            // as we do NOT want to affect the initialData in the render method of AppUi
            resolve();
        })();
    })
);

const InitializedApp = () => {
    const dispatch = useDispatch();
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        Promise.all(promises).then(data => {
            const {app, user} = data?.[0] || {};

            if (CommonUtils.isOneAppLocaleCookieSet()) {
                delete user.userLocale;
            } else {
                CommonUtils.setOneAppLocaleCookie(user.userLocale);
            }

            dispatch(appUpdate(app));
            dispatch(userUpdate(user));
            setIsInitialized(true);
        });
    }, [dispatch]);

    if (
        window.location.href?.includes(CONDITION_MANAGEMENT_PREFIX) &&
        !isInitialized
    ) {
        return <Loader loadingText={loadingText} />;
    }

    return <Root />;
};

render(
    <ErrorBoundary errorComponent={<Unavailable />}>
        <PulseApp
            i18n={{
                ...i18n,
                interpolation: {
                    defaultVariables: {
                        companyName: 'Teladoc Health',
                        inTouchDevice: 'Teladoc Health',
                    },
                },
            }}
            build={{
                key: 'mp-build-tag',
                value: process.env.BUILD_TAG,
            }}
            loadingText={loadingText}
            render={({data, status}) => {
                const App = () => (
                    <Provider store={createStore()}>
                        <PersistGate loading={null} persistor={persistor}>
                            <FeatureFlagProvider featureFlags={featureFlags}>
                                <InitializedApp />
                            </FeatureFlagProvider>
                        </PersistGate>
                    </Provider>
                );

                switch (status) {
                    case STATUSES.INITIALIZED:
                        return App();
                    case STATUSES.ERROR:
                        if (IS_DEV) {
                            // eslint-disable-next-line no-console
                            console.log('[ERROR: INITIAL LOAD]', data);
                        }

                        // ERROR Handling
                        return <Unavailable />;
                }
            }}
        />
    </ErrorBoundary>,
    document.getElementById('root')
);
